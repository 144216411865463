import React, { Component } from "react"
import { withPrefix } from "gatsby"
import { Link } from "gatsby"

import "./index.scss"
import Layout from "../../components/Layout"
import Hero from "../../components/Hero"
import ContentBlock from "../../components/ContentBlock"

import Interstitial from "../../components/ExternalInterstitial/Interstitial"
import HelmetWrapper from "../../components/HelmetWrapper"

import headerHero from "../../assets/images/header_home.jpg"
import sdsPDF from "../../../static/static/Safety_Data_Sheet.pdf"

const pageTitle = "Sitemap - Harmony Biosciences"
const pageDescription =
  "We develop and commercialize novel therapies for rare diseases, focusing on central nervous system disorders. Learn more about our products here."
const pageKeywords = "sitemap"

class sitemap extends Component {
  render() {
    return (
      <div id="sitemap">
        <Layout jobCode="US-HAR-2200062/Aug 2022">
          <HelmetWrapper
            pageTitle={pageTitle}
            pageDescription={pageDescription}
            pageKeywords={pageKeywords}
            slug="/sitemap"
          />
          <Hero bg={headerHero}>
            <h1>Sitemap</h1>
          </Hero>
          <ContentBlock color="white">
            <ul className="sitemap-links">
              <li className="page-section ">About Us</li>
              <li className="">
                <Link to="/about-us">Overview</Link>
              </li>
              <li className="">
                <Link to="/about-us/senior-leadership">Senior Leadership</Link>
              </li>
              <li className="">
                <Link to="/about-us/our-culture">Culture</Link>
              </li>
              <li>
                <Link to="/about-us/careers">Careers</Link>
              </li>
              <li className="">
                <Link to="/about-us/ethics-and-compliance">
                  Ethics/Compliance
                </Link>
              </li>
              <li className="section-mobile">
                <Link to="/about-us/contact-us">Contact Us</Link>
              </li>
              <li className="">
                <Link to="/privacy-policy-terms-of-use">Privacy Policy</Link>
              </li>
              <li className="">
                <Link to="/privacy-policy-terms-of-use#terms">
                  Terms of Use
                </Link>
              </li>

              <li className="page-section">Patients</li>
              <li>
                <Link to="/patients">About Narcolepsy</Link>
              </li>

              <li>
                <Link to="/patients/research-focus-areas">
                  Research Focus Areas
                </Link>
              </li>

              <li>
                <Link to="/patients/educational-resources">
                  Educational Resources
                </Link>
              </li>
              <li className="page-section">Our Products</li>
              <li>
                <Link to="/our-products">Product Listing</Link>
              </li>

              <li className="page-section">
                <Link to="/science">Science</Link>
              </li>
              <li>
                <a href={sdsPDF} target="_blank" rel="noopener noreferrer">
                  Safety Data Sheet
                </a>
              </li>

              <li className="page-section ">Community</li>
              <li>
                <Link to="/funding-programs/patients-at-the-heart">
                  Patients at the Heart Program
                </Link>
              </li>
              <li>
                <Link to="/funding-programs/progress-at-the-heart">
                  Progress at the Heart Program
                </Link>
              </li>
              <li>
                <Link to="/funding-programs/external-funding">
                  External Funding
                </Link>
              </li>
              <li>
                <Link to="/funding-programs/medical-education-grants">
                  Medical Education Grants
                </Link>
              </li>

              <li>
                <Link to="/funding-programs/investigator-sponsored-studies">
                  Investigator Sponsored Studies
                </Link>
              </li>

              <li className="page-section">Newsroom</li>
              <li>
                <Link to="/newsroom">Press Releases</Link>
              </li>

              <li className="page-section">Investor Relations</li>
              <li>
                <Link to="https://ir.harmonybiosciences.com">Investors</Link>
              </li>

              <li className="page-section">Social Media</li>
              <li>
                <a
                  href="https://www.linkedin.com/company/harmonybiosciences/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  LinkedIn
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/harmony_biosciences/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Instagram
                </a>
              </li>

              <li>
                <a
                  href="https://x.com/harmonybio"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  X
                </a>
              </li>
              <li>
                <a
                  href={withPrefix(
                    "/static/Harmony_Corporate_Social_Media_Community_Guidelines__August_2023_Update.pdf"
                  )}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Community Guidelines
                </a>
              </li>
            </ul>
            <p></p>
          </ContentBlock>
          <Interstitial />
        </Layout>
      </div>
    )
  }
}

export default sitemap
